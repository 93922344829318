define("discourse/plugins/swapd-support-tickets/discourse/templates/connectors/user-main-nav/xswapd-rank", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.site.mobileView}}
    {{#if this.model.swapd_rank_can_see}}
      <LinkTo @route="userActivity.report">
        <img class="sr__trophy" src="/plugins/swapd-support-tickets/trophy.png">
        <span>{{i18n "support_tickets.user_report.report"}}</span>
      </LinkTo>
    {{/if}}
  {{/if}}
  
  */
  {
    "id": "kGej1PuZ",
    "block": "[[[41,[30,0,[\"site\",\"mobileView\"]],[[[41,[30,0,[\"model\",\"swapd_rank_can_see\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@route\"],[\"userActivity.report\"]],[[\"default\"],[[[[1,\"\\n      \"],[10,\"img\"],[14,0,\"sr__trophy\"],[14,\"src\",\"/plugins/swapd-support-tickets/trophy.png\"],[12],[13],[1,\"\\n      \"],[10,1],[12],[1,[28,[35,4],[\"support_tickets.user_report.report\"],null]],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null]],[]],null]],[],false,[\"if\",\"link-to\",\"img\",\"span\",\"i18n\"]]",
    "moduleName": "discourse/plugins/swapd-support-tickets/discourse/templates/connectors/user-main-nav/xswapd-rank.hbs",
    "isStrictMode": false
  });
});