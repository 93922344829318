define("discourse/plugins/swapd-support-tickets/discourse/templates/components/step-two", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#start-step
    title=this.title
    subTitle=this.helpMsg
    next=(action "next")
    nextDisabled=this.nextDisabled
    nextLabel="support_tickets.start.next"
    loading=this.loading
    step=this.step}}
  
    <div class="start__step-content">
      <select class="start__select" onchange={{action "setValue" value="target.value"}}>
        <option value="" selected="" disabled="">{{i18n "support_tickets.start.choose"}}</option>
  
        {{#each this.options as |opt|}}
          <option value={{opt}} selected={{eq this.model.type (lowercase opt)}}>
            {{opt}}
          </option>
        {{/each}}
      </select>
    </div>
  
  {{/start-step}}
  */
  {
    "id": "hAiN/aYz",
    "block": "[[[6,[39,0],null,[[\"title\",\"subTitle\",\"next\",\"nextDisabled\",\"nextLabel\",\"loading\",\"step\"],[[30,0,[\"title\"]],[30,0,[\"helpMsg\"]],[28,[37,1],[[30,0],\"next\"],null],[30,0,[\"nextDisabled\"]],\"support_tickets.start.next\",[30,0,[\"loading\"]],[30,0,[\"step\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"start__step-content\"],[12],[1,\"\\n    \"],[10,\"select\"],[14,0,\"start__select\"],[15,\"onchange\",[28,[37,1],[[30,0],\"setValue\"],[[\"value\"],[\"target.value\"]]]],[12],[1,\"\\n      \"],[10,\"option\"],[14,2,\"\"],[14,\"selected\",\"\"],[14,\"disabled\",\"\"],[12],[1,[28,[35,5],[\"support_tickets.start.choose\"],null]],[13],[1,\"\\n\\n\"],[42,[28,[37,7],[[28,[37,7],[[30,0,[\"options\"]]],null]],null],null,[[[1,\"        \"],[10,\"option\"],[15,2,[30,1]],[15,\"selected\",[28,[37,8],[[30,0,[\"model\",\"type\"]],[28,[37,9],[[30,1]],null]],null]],[12],[1,\"\\n          \"],[1,[30,1]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"]],[]]]]]],[\"opt\"],false,[\"start-step\",\"action\",\"div\",\"select\",\"option\",\"i18n\",\"each\",\"-track-array\",\"eq\",\"lowercase\"]]",
    "moduleName": "discourse/plugins/swapd-support-tickets/discourse/templates/components/step-two.hbs",
    "isStrictMode": false
  });
});