define("discourse/plugins/swapd-support-tickets/discourse/templates/connectors/after-topic-footer-buttons/ticket-tag-selector-conn", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.siteSettings.support_tickets_enabled}}
  {{#if this.args.topic.isPrivateMessage}}
  {{#if this.args.topic.support_ticket_created}}
  {{#unless this.args.topic.closed}}
  {{#if this.currentUser.can_view_tickets}}
    {{dropdown-select-box
      class="ticket-status-dropdown"
      nameProperty=null
      content=this.options
      value=this.selectedTag
      valueProperty=null
      onChange=(action "setTicketTag")
      options=(hash
        showCaret=true
      )
    }}
  {{/if}}
  {{/unless}}
  {{/if}}
  {{/if}}
  {{/if}}
  
  */
  {
    "id": "G3hFG0Qe",
    "block": "[[[41,[30,0,[\"siteSettings\",\"support_tickets_enabled\"]],[[[41,[30,0,[\"args\",\"topic\",\"isPrivateMessage\"]],[[[41,[30,0,[\"args\",\"topic\",\"support_ticket_created\"]],[[[41,[51,[30,0,[\"args\",\"topic\",\"closed\"]]],[[[41,[30,0,[\"currentUser\",\"can_view_tickets\"]],[[[1,\"  \"],[1,[28,[35,2],null,[[\"class\",\"nameProperty\",\"content\",\"value\",\"valueProperty\",\"onChange\",\"options\"],[\"ticket-status-dropdown\",null,[30,0,[\"options\"]],[30,0,[\"selectedTag\"]],null,[28,[37,3],[[30,0],\"setTicketTag\"],null],[28,[37,4],null,[[\"showCaret\"],[true]]]]]]],[1,\"\\n\"]],[]],null]],[]],null]],[]],null]],[]],null]],[]],null]],[],false,[\"if\",\"unless\",\"dropdown-select-box\",\"action\",\"hash\"]]",
    "moduleName": "discourse/plugins/swapd-support-tickets/discourse/templates/connectors/after-topic-footer-buttons/ticket-tag-selector-conn.hbs",
    "isStrictMode": false
  });
});