define("discourse/plugins/swapd-support-tickets/discourse/initializers/add-topic-button", ["exports", "discourse/lib/plugin-api", "discourse/lib/ajax", "I18n", "discourse/lib/ajax-error"], function (_exports, _pluginApi, _ajax, _I18n, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function registerTopicFooterButton(api, container) {
    api.registerTopicFooterButton({
      id: "swapd-support-tickets",
      icon: "cart-shopping",
      priority: 200,
      title: "support_tickets.checkout_ticket",
      label: "support_tickets.checkout_ticket",
      action() {
        this.topic.createTicket(this.topic);
      },
      dropdown: false,
      classNames: ["swapd-support-ticket-button"],
      dependentKeys: ["topic.isPrivateMessage", "topic.closed", "topic.support_ticket_created"],
      displayed() {
        return this.get("topic.isPrivateMessage") && !this.get("topic.closed");
      },
      disabled() {
        return this.get("topic.support_ticket_created");
      }
    });
  }
  var _default = _exports.default = {
    name: "extend-for-support-ticket-button",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (!siteSettings.swapd_interested_enabled) {
        return;
      }
      (0, _pluginApi.withPluginApi)("0.8.28", api => registerTopicFooterButton(api, container));
    }
  };
});