define("discourse/plugins/swapd-support-tickets/discourse/templates/components/step-three", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#start-step
    title=this.title
    subTitle=this.subTitle
    next=(action "next")
    nextDisabled=this.nextDisabled
    nextLabel="support_tickets.start.next"
    step=this.step}}
  
    <div class="start__step-content">
      <Input
        @value={{this.model.price}}
        class="start__input start__price-input"
        placeholder={{i18n "support_tickets.start.usd_only"}}
        min="0"
      />
    </div>
  
  {{/start-step}}
  */
  {
    "id": "NtHOQfJ/",
    "block": "[[[6,[39,0],null,[[\"title\",\"subTitle\",\"next\",\"nextDisabled\",\"nextLabel\",\"step\"],[[30,0,[\"title\"]],[30,0,[\"subTitle\"]],[28,[37,1],[[30,0],\"next\"],null],[30,0,[\"nextDisabled\"]],\"support_tickets.start.next\",[30,0,[\"step\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"start__step-content\"],[12],[1,\"\\n    \"],[8,[39,3],[[24,0,\"start__input start__price-input\"],[16,\"placeholder\",[28,[37,4],[\"support_tickets.start.usd_only\"],null]],[24,\"min\",\"0\"]],[[\"@value\"],[[30,0,[\"model\",\"price\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n\"]],[]]]]]],[],false,[\"start-step\",\"action\",\"div\",\"input\",\"i18n\"]]",
    "moduleName": "discourse/plugins/swapd-support-tickets/discourse/templates/components/step-three.hbs",
    "isStrictMode": false
  });
});