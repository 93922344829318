define("discourse/plugins/swapd-support-tickets/discourse/templates/components/step-four", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#start-step
    title=this.title
    subTitle=this.subTitle
    next=(action "next")
    nextDisabled=false
    nextLabel="support_tickets.start.begin"
    step=this.step}}
  
    <div class="start__step-content">
      {{d-editor value=this.model.comment}}
    </div>
  
  {{/start-step}}
  
  */
  {
    "id": "AKxcaYKw",
    "block": "[[[6,[39,0],null,[[\"title\",\"subTitle\",\"next\",\"nextDisabled\",\"nextLabel\",\"step\"],[[30,0,[\"title\"]],[30,0,[\"subTitle\"]],[28,[37,1],[[30,0],\"next\"],null],false,\"support_tickets.start.begin\",[30,0,[\"step\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"start__step-content\"],[12],[1,\"\\n    \"],[1,[28,[35,3],null,[[\"value\"],[[30,0,[\"model\",\"comment\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"]],[]]]]]],[],false,[\"start-step\",\"action\",\"div\",\"d-editor\"]]",
    "moduleName": "discourse/plugins/swapd-support-tickets/discourse/templates/components/step-four.hbs",
    "isStrictMode": false
  });
});