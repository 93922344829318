define("discourse/plugins/swapd-support-tickets/discourse/templates/components/start-step", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="start__header">
    <h2>{{{this.title}}}</h2>
  
    {{#if this.subTitle}}
      <p>{{{this.subTitle}}}</p>
    {{/if}}
  
    {{#if this.helpMsg}}
      <div class="start__help-msg">
        {{{this.helpMsg}}}
      </div>
    {{/if}}
  </div>
  
  <div class="start__main">
  
    {{yield}}
  
    {{#conditional-loading-spinner condition=this.loading}}
      {{#if this.next}}
        {{d-button
          action=this.next
          label=this.nextLabel
          disabled=this.nextDisabled
          class="btn-large btn-danger start__next-btn"}}
      {{/if}}
  
      {{#if this.backEnabled}}
        <a {{action "back"}} class="start__back">
          {{d-icon "chevron-left"}}
          {{i18n "support_tickets.start.back"}}
        </a>
      {{/if}}
    {{/conditional-loading-spinner}}
  </div>
  
  <div class="start__footer">
    {{#unless this.currentUser}}
      <a href="#" {{action (route-action "showLogin")}}>
        {{{i18n "support_tickets.start.footer_links.login"}}}
      </a>
    {{/unless}}
  
    {{{i18n "support_tickets.start.footer"}}}
  
  </div>
  
  */
  {
    "id": "qSIlxOGt",
    "block": "[[[10,0],[14,0,\"start__header\"],[12],[1,\"\\n  \"],[10,\"h2\"],[12],[2,[30,0,[\"title\"]]],[13],[1,\"\\n\\n\"],[41,[30,0,[\"subTitle\"]],[[[1,\"    \"],[10,2],[12],[2,[30,0,[\"subTitle\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"helpMsg\"]],[[[1,\"    \"],[10,0],[14,0,\"start__help-msg\"],[12],[1,\"\\n      \"],[2,[30,0,[\"helpMsg\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"start__main\"],[12],[1,\"\\n\\n  \"],[18,1,null],[1,\"\\n\\n\"],[6,[39,5],null,[[\"condition\"],[[30,0,[\"loading\"]]]],[[\"default\"],[[[[41,[30,0,[\"next\"]],[[[1,\"      \"],[1,[28,[35,6],null,[[\"action\",\"label\",\"disabled\",\"class\"],[[30,0,[\"next\"]],[30,0,[\"nextLabel\"]],[30,0,[\"nextDisabled\"]],\"btn-large btn-danger start__next-btn\"]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"backEnabled\"]],[[[1,\"      \"],[11,3],[24,0,\"start__back\"],[4,[38,8],[[30,0],\"back\"],null],[12],[1,\"\\n        \"],[1,[28,[35,9],[\"chevron-left\"],null]],[1,\"\\n        \"],[1,[28,[35,10],[\"support_tickets.start.back\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null]],[]]]]],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"start__footer\"],[12],[1,\"\\n\"],[41,[51,[30,0,[\"currentUser\"]]],[[[1,\"    \"],[11,3],[24,6,\"#\"],[4,[38,8],[[30,0],[28,[37,12],[\"showLogin\"],null]],null],[12],[1,\"\\n      \"],[2,[28,[37,10],[\"support_tickets.start.footer_links.login\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[2,[28,[37,10],[\"support_tickets.start.footer\"],null]],[1,\"\\n\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"div\",\"h2\",\"if\",\"p\",\"yield\",\"conditional-loading-spinner\",\"d-button\",\"a\",\"action\",\"d-icon\",\"i18n\",\"unless\",\"route-action\"]]",
    "moduleName": "discourse/plugins/swapd-support-tickets/discourse/templates/components/start-step.hbs",
    "isStrictMode": false
  });
});