define("discourse/plugins/swapd-support-tickets/discourse/templates/connectors/after-topic-footer-buttons/next-prev-ticket", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.topic.show_next_prev_btn}}
    {{d-button  icon="chevron-left"
                disabled=(unless this.args.topic.prev_ticket true)
                class="prev-ticket-btn btn-large"
                action=(action "prevTicket" this.args.topic.next_ticket)
                label=(unless this.site.mobileView "support_tickets.prev_ticket")}}
  
    {{d-button  icon="chevron-right"
                disabled=(unless this.args.topic.next_ticket true)
                class="next-ticket-btn btn-large"
                action=(action "nextTicket" this.args.topic.prev_ticket)
                label=(unless this.site.mobileView "support_tickets.next_ticket")}}
  {{/if}}
  
  */
  {
    "id": "2wxsHU3b",
    "block": "[[[41,[30,0,[\"topic\",\"show_next_prev_btn\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"icon\",\"disabled\",\"class\",\"action\",\"label\"],[\"chevron-left\",[52,[51,[30,0,[\"args\",\"topic\",\"prev_ticket\"]]],true],\"prev-ticket-btn btn-large\",[28,[37,3],[[30,0],\"prevTicket\",[30,0,[\"args\",\"topic\",\"next_ticket\"]]],null],[52,[51,[30,0,[\"site\",\"mobileView\"]]],\"support_tickets.prev_ticket\"]]]]],[1,\"\\n\\n  \"],[1,[28,[35,1],null,[[\"icon\",\"disabled\",\"class\",\"action\",\"label\"],[\"chevron-right\",[52,[51,[30,0,[\"args\",\"topic\",\"next_ticket\"]]],true],\"next-ticket-btn btn-large\",[28,[37,3],[[30,0],\"nextTicket\",[30,0,[\"args\",\"topic\",\"prev_ticket\"]]],null],[52,[51,[30,0,[\"site\",\"mobileView\"]]],\"support_tickets.next_ticket\"]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"d-button\",\"unless\",\"action\"]]",
    "moduleName": "discourse/plugins/swapd-support-tickets/discourse/templates/connectors/after-topic-footer-buttons/next-prev-ticket.hbs",
    "isStrictMode": false
  });
});