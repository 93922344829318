define("discourse/plugins/swapd-support-tickets/discourse/templates/components/open-tickets-count", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.currentUser.open_tickets_count}}
    <div class="open-tickets-count-container">
      <LinkTo @route="userPrivateMessages.tickets">
        {{i18n "support_tickets.open_tickets" count=this.currentUser.open_tickets_count}}
      </LinkTo>
    </div>
  {{/if}}
  
  */
  {
    "id": "GQxGV675",
    "block": "[[[41,[30,0,[\"currentUser\",\"open_tickets_count\"]],[[[1,\"  \"],[10,0],[14,0,\"open-tickets-count-container\"],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@route\"],[\"userPrivateMessages.tickets\"]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,3],[\"support_tickets.open_tickets\"],[[\"count\"],[[30,0,[\"currentUser\",\"open_tickets_count\"]]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"div\",\"link-to\",\"i18n\"]]",
    "moduleName": "discourse/plugins/swapd-support-tickets/discourse/templates/components/open-tickets-count.hbs",
    "isStrictMode": false
  });
});