define("discourse/plugins/swapd-support-tickets/discourse/components/step-one", ["exports", "discourse-common/utils/decorators", "discourse/lib/ajax", "discourse/lib/url", "@ember/service", "@ember/template", "@ember/utils", "@ember/component"], function (_exports, _decorators, _ajax, _url, _service, _template, _utils, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const BASE_I18N = "support_tickets.start.steps.";
  var _default = _exports.default = _component.default.extend(dt7948.p({
    dialog: (0, _service.inject)(),
    title: (0, _template.htmlSafe)(I18n.t("support_tickets.start.title.steps.one.main")),
    nextDisabled(seller_or_buyer) {
      if ((0, _utils.isBlank)(seller_or_buyer)) return true;
      return false;
    },
    actions: {
      next() {
        if (this.get("nextDisabled")) return;
        this.set("loading", true);
        (0, _ajax.ajax)("/start/check_verified", {
          type: "GET",
          data: {
            target_username: this.get("model.target_username"),
            role: this.get("model.seller_or_buyer")
          }
        }).then(result => {
          if (result.ok == "false" || result.ok == false) {
            const msg = (0, _template.htmlSafe)(I18n.t(BASE_I18N + result.msg, {
              username: this.get("model.target_username")
            }));
            this.dialog.alert({
              message: msg
            });
            _url.default.routeTo("/");
          } else {
            this.set("model.target_vip_level", result.vip_level);
            this.set("model.target_trustap_full_user", result.trustap_full_user);
            this.set("model.allowed_payment_methods", result.allowed_payment_methods);
            this.set("loading", false);
            this.set("step", 2);
          }
        }).finally(() => {});
      }
    }
  }, [["method", "nextDisabled", [(0, _decorators.default)("model.seller_or_buyer")]]]));
});