define("discourse/plugins/swapd-support-tickets/discourse/templates/connectors/topic-above-footer-buttons/ticket-status-conn", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.siteSettings.support_tickets_enabled}}
    {{ticket-status topic=this.args.model}}
  {{/if}}
  
  */
  {
    "id": "3MqDwZw/",
    "block": "[[[41,[30,0,[\"siteSettings\",\"support_tickets_enabled\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"topic\"],[[30,0,[\"args\",\"model\"]]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"ticket-status\"]]",
    "moduleName": "discourse/plugins/swapd-support-tickets/discourse/templates/connectors/topic-above-footer-buttons/ticket-status-conn.hbs",
    "isStrictMode": false
  });
});