define("discourse/plugins/swapd-support-tickets/discourse/templates/connectors/user-messages-nav/tickets", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (canSeeTickets this.model)}}
    <LinkTo @model="userPrivateMessages.tickets">
      {{d-icon "cart-shopping"}}
      {{i18n "user.messages.tickets"}}
    </LinkTo>
  {{/if}}
  
  */
  {
    "id": "8r81L5Wx",
    "block": "[[[41,[28,[37,1],[[30,0,[\"model\"]]],null],[[[1,\"  \"],[8,[39,2],null,[[\"@model\"],[\"userPrivateMessages.tickets\"]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,3],[\"cart-shopping\"],null]],[1,\"\\n    \"],[1,[28,[35,4],[\"user.messages.tickets\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"canSeeTickets\",\"link-to\",\"d-icon\",\"i18n\"]]",
    "moduleName": "discourse/plugins/swapd-support-tickets/discourse/templates/connectors/user-messages-nav/tickets.hbs",
    "isStrictMode": false
  });
});